<template>
  <VueFinalModal name="update" v-bind="$attrs" classes="modal-container" content-class="modal-content">
    <div id="telegramModal" class="box-modal promo-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="onCancel">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">{{ $t("modal.update.title") }}</div>
      <div class="promo-modal__text">{{ $t("modal.update.desc") }}</div>
      <div class="promo-modal__btns">
        <button class="promo-modal__btn promo-modal__btn-cancel" type="button" @click="onCancel">{{
          $t("modal.update.later") }}</button>

        <button class="promo-modal__btn promo-modal__btn-confirm" type="button" @click="onSuccess">{{
          $t("modal.update.submit") }}</button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, $vfm } from "vue-final-modal";

const props = defineProps({
  clearBuild: {
    type: Function,
  },
});

const onCancel = async () => {
  $vfm.hide("update");
};
const onSuccess = async () => {
  if (props.clearBuild) {
    props.clearBuild();
  } else {
    if (caches) {
      const keys = await caches.keys();
      if (keys.length) {
        await Promise.all(
          keys.map(async (key) => {
            await caches.delete(key);
          })
        );
      }
    }
    // window.location.reload(true);
  }
};
</script>

<style lang="scss">
.promo-modal {
  &__text {
    color: #958dc0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__btn {
    border-radius: 8px;
    padding: 12px 25px;
    font-weight: 600;
    font-size: 17px;

    &-confirm {
      background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
      color: white;
    }

    &-cancel {
      color: #958dc0;
      border: 2px solid #958dc0;
      margin-right: 15px;
      background: transparent;
    }
  }
}</style>
